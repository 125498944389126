import React from 'react'
import './styles.css'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledBox = styled(Box)(() => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  width: '100%',
  boxSizing: 'border-box',
}))
const WaveOverlay = () => {
  return (
    <StyledBox>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1380 120'>
        <path
          fill='#73b9fe'
          fillOpacity='1'
          d='M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z'
        ></path>
      </svg>
    </StyledBox>
  )
}

export default WaveOverlay
